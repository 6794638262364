/* Copyright Levelise Ltd 2024 */
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import './index.css';
import NavBar from '../../components/NavBar';
import { PERMISSIONS, facility, routes } from '../../utils/constants';
import CustomerListCoT from '../../components/CustomerListCoT';
import FacilityDetailTabCoT from '../../components/FacilityDetailTabCoT';
import FacilityAddressCoT from '../../components/FacilityAddressCoT';
import OldCustomerTabCoT from '../../components/OldCustomerTabCoT';
import NewCustomerTabCoT from '../../components/NewCustomerTabCoT';
import FleetService from '../../services/fleet-service';
import { hasPermission } from '../../utils/utils';
import Popup from '../../components/Popup';

function AddCustomerRoute() {
	const navigate = useNavigate();

	const alert = useAlert();

	const [currentDrus, setCurrentDrus] = useState([]);

	const [loading, setLoading] = useState(false);
	const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);

	const [selectedCustomer, setSelectedCustomer] = useState(null);
	const [selectedFacility, setSelectedFacility] = useState(null);
	const [newCustomer, setNewCustomer] = useState(null);

	const resetState = () => {
		setSelectedFacility(null);
		setSelectedCustomer(null);
		setNewCustomer(null);
	};

	const onCustomerSelected = async (customer) => {
		if (!customer || !Object.hasOwn(customer, 'customerId') || !hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER)) {
			return;
		}

		try {
			const customerFacilities = await FleetService.getCustomerFacilities(customer.customerId);
			if (
				customerFacilities &&
				Object.hasOwn(customerFacilities, 'facilities') &&
				customerFacilities.facilities.length > 0
			) {
				const facility = customerFacilities.facilities[0];
				setSelectedFacility(facility);
				setSelectedCustomer(customer);
			} else {
				alert.show("Couldn't find selected customer's faciliy.");
				resetState();
			}
		} catch (error) {
			alert.show('Something went wrong, please try again.');
			resetState();
		}
	};

	const onDruSelected = async (dru) => {
		if (
			typeof dru === 'undefined' ||
			dru === null ||
			dru === '' ||
			!hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER)
		) {
			return;
		}

		try {
			const facility = await FleetService.getFacilityByDruId(dru);
			if (facility && Object.hasOwn(facility, 'name')) {
				facility.druId = dru;
				const customer = await FleetService.getCustomerByFacility(facility.name);
				if (customer) {
					setSelectedFacility(facility);
					setSelectedCustomer(customer);
				} else {
					alert.show("Couldn't find selected faciliy's customer.");
					resetState();
				}
			} else {
				alert.show("Couldn't find selected dru's facility information.");
				resetState();
			}
		} catch (err) {
			alert.show('Something went wrong, please try again.');
			resetState();
		}
	};

	const onSelectSearchResult = (item, type) => {
		if (type === 'customer') {
			onCustomerSelected(item);
		}

		if (type === 'dru') {
			onDruSelected(item);
		}
	};

	const fetchCurrentDrus = async () => {
		try {
			const res = await FleetService.getDruList();
			if (res) {
				setCurrentDrus(res);
			}
		} catch (err) {
			setCurrentDrus([]);
		}
	};

	const createUserAndCustomer = async (forename, surname, email, phone, cot, address1) => {
		// create user
		const user = await FleetService.addUser({ forename, surname, email });

		if (user && user?.created) {
			const { userId } = user;

			// create customer and change tenant
			const res = await FleetService.createCustomer({
				forename,
				surname,
				email,
				phone,
				userId,
				cot,
			});

			if (res && res?.created) {
				setConfirmationModalVisible(false);

				alert.show(
					`Customer created successfully, and is now current occupier of the facility at ${address1}.`,
					{
						type: 'success',
					}
				);

				setSelectedCustomer(null);
				setSelectedFacility(null);

				navigate(`/customer/${res?.customerId}`, { replace: true });
			}
		}
	};

	const removeTenant = async (cot, address1) => {
			// create customer with no cusotmer data, it unlinks the current customer from the facility.
			const body = { cot };
			
			const res = await FleetService.createCustomer(body);

			if (res && res?.created) {
				setConfirmationModalVisible(false);

				alert.show(
					`Customer removed successfully from the facility at ${address1}.`,
					{
						type: 'success',
					}
				);

				setSelectedCustomer(null);
				setSelectedFacility(null);
			}
	};

	const changeTenant = async (obj) => {
		if (!obj || !Object.keys(obj).length > 0) {
			return;
		}

		setLoading(true);
		try {
			const {
				forename,
				surname,
				phone,
				email,
				prevCustomerId,
				reason,
				notifier,
				transferDate,
				address1,
				isCustomerKnown,
			} = obj;

			const cot = { prevCustomerId, reason, notifier, transferDate };

			if (isCustomerKnown) {
				await createUserAndCustomer(forename, surname, email, phone, cot, address1);
			}

			if (!isCustomerKnown) {
				await removeTenant(cot, address1);
			}

			setLoading(false);
		} catch (error) {
			setLoading(false);
			alert.show('Something went wrong, please try again!');
			console.log(error);
		}
	};

	const handleChangeTenant = (obj) => {
		setNewCustomer(obj);
		setConfirmationModalVisible(true);
	};

	useEffect(() => {
		fetchCurrentDrus();
	}, []);

	return (
		<div style={{ height: '100vh' }}>
			<NavBar route={routes.customer} />
			<div className="add-customer-container">
				<div className="customer-search-container container-border-style">
					<CustomerListCoT
						currentDrus={currentDrus}
						onSelectSearchResult={onSelectSearchResult}
						selectedCustomer={selectedCustomer}
						selectedFacility={selectedFacility}
					/>
				</div>
				<div className="facility-details-container container-border-style">
					<FacilityDetailTabCoT facility={selectedFacility} />
				</div>
				<div className="add-customer-address-container container-border-style">
					<FacilityAddressCoT customer={selectedCustomer} />
				</div>
				<div className="old-customer-container container-border-style">
					<OldCustomerTabCoT customer={selectedCustomer} />
				</div>
				<div className="new-customer-container container-border-style">
					<NewCustomerTabCoT
						customer={selectedCustomer}
						facility={selectedFacility}
						handleChangeTenant={handleChangeTenant}
					/>
				</div>
			</div>
			{hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT) && confirmationModalVisible && (
				<Popup
					type="command"
					text={`Please confirm that you want to change the tenant of ${selectedCustomer?.address1}${newCustomer?.isCustomerKnown ? ` to ${newCustomer?.forename} ${newCustomer?.surname}` : '' }.`}
					onConfirm={() => changeTenant(newCustomer)}
					confirm={'Confirm'}
					onClose={() => setConfirmationModalVisible(false)}
					close={'Cancel'}
					textStyle={{
						marginTop: 0,
						padding: 10,
					}}
					disableConfirm={!newCustomer || !facility || loading}
				/>
			)}
		</div>
	);
}

export default AddCustomerRoute;
