/* Copyright Levelise Ltd 2019-2024 */
import * as types from './chart'

export const PERMISSIONS = {
    CAN_CREATE_FACILITY: ['ADMIN'],
    CAN_ACCESS_AF: ['ADMIN', 'PROVIDER_SUPPORT', 'PROVIDER_CS', 'PROVIDER_USER'],
    CAN_CONTROL_DRU : ['ADMIN', 'PROVIDER_SUPPORT'],
    CAN_ACCESS_ORDERS : ['ADMIN', 'PROVIDER_SUPPORT'],
    CAN_ACCESS_CUSTOMER: ['ADMIN', 'PROVIDER_SUPPORT', 'PROVIDER_CS'],
    CAN_ACCESS_CUSTOMER_FACILITIES: ['ADMIN', 'PROVIDER_SUPPORT', 'PROVIDER_CS'],
    CAN_ACCESS_QUALIFICATIONS: ['ADMIN', 'PROVIDER_SUPPORT', 'PROVIDER_CS', 'PROVIDER_USER'],
    CAN_ACCESS_DR_BATTERY: ['ADMIN', 'PROVIDER_SUPPORT', 'PROVIDER_CS', 'PROVIDER_USER', 'INSTALLER_ADMIN', 'INSTALLER_USER'],
    CAN_ACCESS_CUSTOMER_CONSENT: ['ADMIN', 'PROVIDER_SUPPORT', 'PROVIDER_CS'],
    CAN_EDIT_CUSTOMER_CONSENT: ['ADMIN', 'PROVIDER_SUPPORT', 'PROVIDER_CS']
}

export const ROLES = {
    admin: 'ROLE_ADMIN',
    user: 'ROLE_USER',
    installer: 'ROLE_INSTALLER_USER',
    installerAdmin: 'ROLE_INSTALLER_ADMIN'
};

export const ADMIN = 'ADMIN';
export const PROVIDER_CS = 'PROVIDER_CS';
export const PROVIDER_SUPPORT = 'PROVIDER_SUPPORT';
export const INSTALLER_USER = 'INSTALLER_USER';
export const STATUS_OPTIONS = ['ALL', 'OPTIMISED', 'UNOPTIMISED'];
export const show = 'show';
export const hide = 'hide';
export const distinct = 'distinct';
export const combined = 'combined';
export const fleet = 'Fleet';
export const facility = 'facility';
export const timestampMsec = 'timestampMsec';
export const timestampSec = 'timestampSec';
export const timestampDay = 'timestampDay';
export const all = 'all';
export const concentrator = 'concentrator';
export const concentratorOaas = 'concentratorOaas';
export const battery = 'battery';
export const count = 'count';
export const min = 'min';
export const max = 'max';
export const mean = 'mean';

export const add = 'add';
export const download = 'download';
export const upload = 'upload';
export const loading = 'loading';
export const success = 'success';

export const acceptEvidenceConfirmationText = "Please confirm that you have checked the evidence and read inventory, and they are correct. Once you have accepted the evidence, you cannot change it."
export const withdrawConsentConfirmationText = "Please confirm that you want to withdraw from Grid Services Agreement, Half-Hourly Collection and Demand Flexibility Service. Once you have withdrawn, you cannot change the values."
export const withdrawDSRConfirmationText = "Please confirm that you want to withdraw from DSR (Demand Side Response). Once you have withdrawn, you cannot change the value."

export const IMPORT_LIMIT_ERROR = 'Import Limit should be in Watts.';
export const EXPORT_LIMIT_ERROR = 'Export Limit should be in Watts.';
export const PV_NET_CAPACITY_SHOULD_BE_IN_WATTS = 'PV Net Capacity should be in Watts.';

export const SEVERITY_COLOR = {
    "OK": { backgroundSeverity: 'rgb(100,182,100)', backgroundFault: 'rgb(100,182,100)' },
    "Noted": { backgroundSeverity: 'rgb(100,182,100)', backgroundFault: 'rgb(100,182,100)' },
    "Glitchy": { backgroundSeverity: 'rgb(34,150,131)', backgroundFault: 'rgb(34,150,131)' },
    "Faulty": { backgroundSeverity: 'rgb(255,215,50)', backgroundFault: 'rgb(255,215,50)' },
    "Diminished": { backgroundSeverity: 'rgb(232,96,53)', backgroundFault: 'rgb(232,96,53)' },
    "Unoptimisable": { backgroundSeverity: 'rgb(168,25,22)', backgroundFault: 'rgb(168,25,22)' },
    "Unsafe": { backgroundSeverity: 'rgb(105,99,170)', backgroundFault: 'rgb(105,99,170)' },
    "Inactive": { backgroundSeverity: 'rgb(0,0,0)', backgroundFault: 'rgb(0,0,0)' },
}
export const routes = {
    login: 'login',
    fleet: 'fleet',
    facility: 'facility',
    configure_facility: 'configure-facility',
    settings: 'settings',
    change_password: 'change-password',
    customer: 'customer',
    fleet_customer: 'fleet_customer',
    orders: 'orders',
    user: 'user'
}

export const resolutions = {
    second: 'second',
    minute: 'minute',
    half_hour: 'half-hour',
    day: 'day',
    week: 'week'
}

export const TIME_FRAMES = {
    all: 'All',
    thirty_six_months: '36 months',
    twelve_months: '12 months',
    three_months: '3 months',
    twenty_one_days: '21 days',
    one_week: '7 days',
    twenty_four_hours: '24 hours',
    six_hours: '6 hours',
    one_hour: '1 hour',
    fifteen_minutes: '15 minutes',
    select: 'Select dates'
}

export const DOWNLOAD_TIMEFRAME = [
    TIME_FRAMES.all,
    TIME_FRAMES.thirty_six_months,
    TIME_FRAMES.twelve_months,
    TIME_FRAMES.three_months,
    TIME_FRAMES.twenty_one_days,
    TIME_FRAMES.one_week,
    TIME_FRAMES.select
]

export const CONTRACT_TIMEFRAME = [
    TIME_FRAMES.twenty_four_hours,
    TIME_FRAMES.twelve_hours,
    TIME_FRAMES.six_hours,
    TIME_FRAMES.one_hour
]

export const FLEET_TIME_FRAMES = [
    TIME_FRAMES.all,
    TIME_FRAMES.thirty_six_months,
    TIME_FRAMES.twelve_months,
    TIME_FRAMES.three_months,
    TIME_FRAMES.twenty_one_days,
    TIME_FRAMES.one_week,
    TIME_FRAMES.twenty_four_hours,
    TIME_FRAMES.six_hours,
    TIME_FRAMES.one_hour,
    TIME_FRAMES.fifteen_minutes,
    TIME_FRAMES.select,
];

export const FACILITY_TIME_FRAMES = [
    TIME_FRAMES.all,
    TIME_FRAMES.thirty_six_months,
    TIME_FRAMES.twelve_months,
    TIME_FRAMES.three_months,
    TIME_FRAMES.twenty_one_days,
    TIME_FRAMES.one_week,
    TIME_FRAMES.twenty_four_hours,
    TIME_FRAMES.six_hours,
    TIME_FRAMES.one_hour,
    TIME_FRAMES.fifteen_minutes,
    TIME_FRAMES.select,
];

export const DATA_INDEX = {
    [TIME_FRAMES.twenty_four_hours]: [0, 1439],
    [TIME_FRAMES.twelve_hours]: [0, 719],
    [TIME_FRAMES.six_hours]: [0, 359],
    [TIME_FRAMES.one_hour]: [0, 59],
    [TIME_FRAMES.fifteen_minutes]: [0, 899],
}

export const  DISCONNECTED_CODE = 500
export const  RECENTLY_DISCONNECTED_CODE = 60001
export const  DISCONNECTION_RECOVERING_CODE = 60002
export const  RECONNECTED_CODE = 60003

export const STATUS = {
    "OK": "ok",
    "No Facility": "noFacility",
    "Retired": "retired",
    "Unsafe": "unsafe",
    "Unoptimisable": "unoptimisable",
    "Diminished": "diminished",
    "Faulty": "faulty",
    "Glitchy": "glitchy",
    "Noted": "noted",
    "Not registered": "unregistered",
}

export const AF_STATUS = {
    "ok": "OK",
    "noFacility": "No Facility",
    "retired": "Retired",
    "unsafe": "Unsafe",
    "unoptimisable": "Unoptimisable",
    "diminished": "Diminished",
    "faulty": "Faulty",
    "glitchy": "Glitchy",
    "noted": "Noted",
    "disconnected": "Disconnected",
    "recovering": "Recovering",
    "unregistered": "Not registered"
}

export const COUNT = {
    "okCount": "OK",
    "severestFaultCount": "Severest fault",
    "inactiveCount": "Inactive",
}

export const INACTIVE = {
    "noFacility": "No Facility",
    "retired": "Retired",
    "unregistered": "Not registered",
}

export const SEVERITY = {
    "noted": "Noted",
    "glitchy": "Glitchy",
    "faulty": "Faulty",
    "diminished": "Diminished",
    "unoptimisable": "Unoptimisable",
    "unsafe": "Unsafe",
}

export const PPM_MINUTE = {
    [types.ppm.type]: 'ppm',
    [types.concentratorPpm.type]: 'concentrator'
}

export const PPM_HALF_HOUR_AND_DAY = {
    [types.ldPpm.type]: 'ld',
    [types.lqPpm.type]: 'lq',
    [types.maxPpm.type]: 'max',
    [types.meanPpm.type]: 'mean',
    [types.medianPpm.type]: 'median',
    [types.minPpm.type]: 'min',
    [types.udPpm.type]: 'ud',
    [types.uqPpm.type]: 'uq'
}

export const POWER_FLOW = {
    [types.toLoad.type]: "loadKwh",
    [types.fromPv.type]: "pvKwh",
    [types.toBattery.type]: "batteryChargingKwh",
    [types.fromBattery.type]: "batteryDischargingKwh",
    [types.fromGrid.type]: "gridIncomingKwh",
    [types.toGrid.type]: "gridOutgoingKwh",
    [types.heating.type]: "hwHeatingKwh",
    [types.toEv.type]: "evChargingKwh",
    [types.fromEv.type]: "evDischargingKwh",
    [types.dcPv.type]: 'dcPvKwh',
    [types.toInverter.type]: 'inverterChargingKwh',
    [types.fromInverter.type]: 'inverterDischargingKwh',
}

export const POWER_FLOW_HALF_HOUR = {
    [types.toLoad.type]: "loadKwh",
    [types.fromPv.type]: "pvKwh",
    [types.toBattery.type]: "batteryChargingKwh",
    [types.fromBattery.type]: "batteryDischargingKwh",
    [types.fromGrid.type]: "gridIncomingKwh",
    [types.toGrid.type]: "gridOutgoingKwh",
    [types.heating.type]: "hwHeatingKwh",
    [types.socEnergy.type]: 'soc',
    [types.toEv.type]: "evChargingKwh",
    [types.fromEv.type]: "evDischargingKwh",
    [types.dcPv.type]: 'dcPvKwh',
    [types.toInverter.type]: 'inverterChargingKwh',
    [types.fromInverter.type]: 'inverterDischargingKwh',
}

export const POWER_FLOW_SECOND = {
    [types.toLoad.type]: "loadW",
    [types.fromPv.type]: "pvW",
    [types.toBattery.type]: "batteryChargingW",
    [types.fromBattery.type]: "batteryDischargingW",
    [types.fromGrid.type]: "gridIncomingW",
    [types.toGrid.type]: "gridOutgoingW",
    [types.heating.type]: "hwHeatingW",
    [types.socEnergy.type]: 'soc',
    [types.dcPv.type]: 'dcPvW',
    [types.toInverter.type]: 'inverterChargingW',
    [types.fromInverter.type]: 'inverterDischargingW',
}

export const POWER_FLOW_MINUTE = {
    [types.toLoad.type]: "loadW",
    [types.fromPv.type]: "pvW",
    [types.toBattery.type]: "batteryChargingW",
    [types.fromBattery.type]: "batteryDischargingW",
    [types.fromGrid.type]: "gridIncomingW",
    [types.toGrid.type]: "gridOutgoingW",
    [types.heating.type]: "hwHeatingW",
    [types.socEnergy.type]: 'soc',
    [types.toEv.type]: "evChargingW",
    [types.fromEv.type]: "evDischargingW",
    [types.dcPv.type]: 'dcPvW',
    [types.toInverter.type]: 'inverterChargingW',
    [types.fromInverter.type]: 'inverterDischargingW',
}

export const POWER_FLOW_DRU = {
    [types.toLoad.type]: "loadPower",
    [types.fromPv.type]: "pvPower",
    [types.toBattery.type]: "batteryChargingPower",
    [types.fromBattery.type]: "batteryDischargingPower",
    [types.fromGrid.type]: "gridIncomingPower",
    [types.toGrid.type]: "gridOutgoingPower"
}

export const DEMAND_RESPONSE = {
    [types.availableCharge.type]: "drAvailableHighKwh",
    [types.availableDischarge.type]: "drAvailableLowKwh",
    [types.dispatchedPower.type]: '',
    [types.dispatchedImport.type]: "drDispatchedImportKwh",
    [types.dispatchedExport.type]: "drDispatchedExportKwh",
    [types.gridFrequency.type]: "frequencyHz",
    [types.acVoltage.type]: "acVoltage"
}

export const DEMAND_RESPONSE_MINUTE = {
    [types.availableCharge.type]: "drAvailableHighW",
    [types.availableDischarge.type]: "drAvailableLowW",
    [types.dispatchedImport.type]: "drDispatchedImportW",
    [types.dispatchedExport.type]: "drDispatchedExportW",
    [types.gridFrequency.type]: "frequencyHz",
    [types.acVoltage.type]: "acVoltage"
}

export const DEMAND_RESPONSE_SECOND = {
    [types.availableCharge.type]: "drAvailableHighW",
    [types.availableDischarge.type]: "drAvailableLowW",
    [types.dispatchedImport.type]: "drDispatchedImportW",
    [types.dispatchedExport.type]: "drDispatchedExportW",
    [types.gridFrequency.type]: "frequencyHz",
    [types.acVoltage.type]: "acVoltage"
}

export const BATTERY = {
    [types.minBatteryVoltage.type]: 'minBatteryVoltage',
    [types.maxBatteryVoltage.type]: 'maxBatteryVoltage',
    [types.soc.type]: 'soc',
    [types.minBatteryTemperature.type]: 'minBatteryTemperature',
    [types.maxBatteryTemperature.type]: 'maxBatteryTemperature',
}

export const BATTERY_SOC = {
    [types.ldSoc.type]: 'ld',
    [types.lqSoc.type]: 'lq',
    [types.maxSoc.type]: 'max',
    [types.meanSoc.type]: 'mean',
    [types.medianSoc.type]: 'median',
    [types.minSoc.type]: 'min',
    [types.udSoc.type]: 'ud',
    [types.uqSoc.type]: 'uq'
}

export const BATTERY_SOC_HALF_HOUR_AND_DAY = {
    [types.ldSoc.type]: 'ld',
    [types.lqSoc.type]: 'lq',
    [types.maxSoc.type]: 'uf',
    [types.meanSoc.type]: 'mean',
    [types.medianSoc.type]: 'median',
    [types.minSoc.type]: 'lf',
    [types.udSoc.type]: 'ud',
    [types.uqSoc.type]: 'uq',
    [types.upperSoc.type]: 'max',
    [types.lowerSoc.type]: 'min'
}

export const BATTERY_TEMP = {
    [types.ldTemp.type]: 'ld',
    [types.lqTemp.type]: 'lq',
    [types.maxTemp.type]: 'max',
    [types.medianTemp.type]: 'median',
    [types.minTemp.type]: 'min',
    [types.udTemp.type]: 'ud',
    [types.uqTemp.type]: 'uq'
}

export const BATTERY_TEMP_HALF_HOUR_AND_DAY = {
    [types.ldTemp.type]: 'ld',
    [types.lqTemp.type]: 'lq',
    [types.maxTemp.type]: 'uf',
    [types.medianTemp.type]: 'median',
    [types.minTemp.type]: 'lf',
    [types.udTemp.type]: 'ud',
    [types.uqTemp.type]: 'uq',
    [types.upperTemp.type]: 'max',
    [types.lowerTemp.type]: 'min'
}

export const HW_FLOW = {
    [types.ldHwFlow.type]: 'ld',
    [types.lqHwFlow.type]: 'lq',
    [types.maxHwFlow.type]: 'max',
    [types.meanHwFlow.type]: 'mean',
    [types.medianHwFlow.type]: 'median',
    [types.minHwFlow.type]: 'min',
    [types.udHwFlow.type]: 'ud',
    [types.uqHwFlow.type]: 'uq'
}

export const HW_TEMP = {
    [types.ldTemp.type]: 'ld',
    [types.lqTemp.type]: 'lq',
    [types.maxTemp.type]: 'max',
    [types.medianTemp.type]: 'median',
    [types.meanTemp.type]: 'mean',
    [types.minTemp.type]: 'min',
    [types.udTemp.type]: 'ud',
    [types.uqTemp.type]: 'uq'
}

export const HW_TEMP_HALF_HOUR_AND_DAY = {
    [types.ldTemp.type]: 'ld',
    [types.lqTemp.type]: 'lq',
    [types.maxTemp.type]: 'uf',
    [types.medianTemp.type]: 'median',
    [types.meanTemp.type]: 'mean',
    [types.minTemp.type]: 'lf',
    [types.udTemp.type]: 'ud',
    [types.uqTemp.type]: 'uq',
    [types.upperTemp.type]: 'max',
    [types.lowerTemp.type]: 'min'
}

export const CONNECTED_DRUS = {
    [types.allConnected.type]: '',
    [types.minConnected.type]: 'min',
    [types.maxConnected.type]: 'max',
    [types.meanConnected.type]: 'mean',
}

export const PANELS = [
    "Values",
    "Load",
    "PV",
    "Battery",
    "Inverter",
    "Frequency Meter",
    "Power Meter"
];

export const HOT_WATER = {
    [types.consumption.type]: "flowL",
    [types.ambient.type]: "ambientTempC",
    [types.inlet.type]: "inletTempC",
    [types.outlet.type]: "outletTempC",
    [types.upTank1.type]: "temp1_8C",
    [types.upTank3.type]: "temp3_8C",
    [types.upTank5.type]: "temp5_8C",
    [types.upTank7.type]: "temp7_8C"
}

export const DEMAND_RESPONSE_FLEET = {
    [types.availableHigh.type]: 'drAvailableHighW',
    [types.availableLow.type]: 'drAvailableLowW',
    [types.dispatchedExport.type]: 'drDispatchedExportW',
    [types.dispatchedImport.type]: 'drDispatchedImportW',
    [types.medianGridFrequency.type]: 'medianFrequencyHz'
}

export const ENERGY_FLEET = {
    [types.toLoad.type]: 'loadW',
    [types.fromPv.type]: 'pvW',
    [types.toBattery.type]: 'batteryChargingW',
    [types.fromBattery.type]: 'batteryDischargingW',
    [types.fromGrid.type]: 'gridIncomingW',
    [types.toGrid.type]: 'gridOutgoingW',
    [types.heating.type]: 'hwHeatingW'
}

export const LOAD_FLEET = {
    [types.fromPv.type]: 'loadFromPvW',
    [types.fromBattery.type]: 'loadFromBatteryW',
    [types.fromGridStack.type]: '',
    [types.fromFleet.type]: ''
}

export const GENERATION_FLEET = {
    [types.toLoad.type]: 'loadFromPvW',
    [types.toBattery.type]: 'batteryFromPvW',
    [types.heating.type]: 'hwFromPvW',
    [types.toFleet.type]: '',
    [types.toGridStack.type]: ''
}

export const DEMAND_RESPONSE_FLEET_KWH = {
    [types.availableHigh.type]: 'drAvailableHighKwh',
    [types.availableLow.type]: 'drAvailableLowKwh',
    [types.dispatchedExport.type]: 'drDispatchedExportKwh',
    [types.dispatchedImport.type]: 'drDispatchedImportKwh',
    [types.medianGridFrequency.type]: 'medianFrequencyHz'
}

export const ENERGY_FLEET_KWH = {
    [types.toLoad.type]: 'loadKwh',
    [types.fromPv.type]: 'pvKwh',
    [types.toBattery.type]: 'batteryChargingKwh',
    [types.fromBattery.type]: 'batteryDischargingKwh',
    [types.fromGrid.type]: 'gridIncomingKwh',
    [types.toGrid.type]: 'gridOutgoingKwh',
    [types.heating.type]: 'hwHeatingKwh'
}

export const LOAD_FLEET_KWH = {
    [types.fromPv.type]: 'loadFromPvKwh',
    [types.fromBattery.type]: 'loadFromBatteryKwh',
    [types.fromGridStack.type]: '',
    [types.fromFleet.type]: ''
}

export const GENERATION_FLEET_KWH = {
    [types.toLoad.type]: 'loadFromPvKwh',
    [types.toBattery.type]: 'batteryFromPvKwh',
    [types.heating.type]: 'hwFromPvKwh',
    [types.toFleet.type]: '',
    [types.toGridStack.type]: ''
}

export const customerInfoTabs = {
    information:'Info',
    facilities:'Facilities'
}
